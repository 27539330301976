import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null,

    usr: {},
    chatList: [],
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SocketUsr(state, data) {
      state.usr = data
    },
    SocketChat(state, data) {
      state.chatList.push(data)
    }
  },
  actions: {
    SocketEnterSuccess({ commit }, data) {
      commit("SocketUsr", data)
    },
    SocketChatFromServer({ commit }, data) {
      commit("SocketChat", data)
    },
  },
})
