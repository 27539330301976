import request from './axios-auth'
import axios from 'axios'

import { mock_stats, mock_stats2, history_data, } from './mock_data'

import { mm_group, lchan_group, rp_group, group_names, group_mapping, groups, } from './constants'

export  async function getStats(strategy = undefined) {

    let params = {
        clean_history: true,
    };

    if (strategy !== undefined && strategy.strip() !== '') {
        params = { strategy, }
    }
    
    let response = await request.post("/v1/action", { method: "get_strategy_statistics", params, })

    console.log(`getStats: ${response.data}`);

    let result = response.data;

    let k = 0

    const data = result.map((item) => {
        const gateway_name = item.gateway_name
        const owner = item.owner

        let k2 = 0
        const statistics = Object.keys(item.statistics).sort().map((item2) => {
            return { key: k2++, item_name: item2, value: item.statistics[item2]}
        })
        
        return {
            key: k++,
            gateway_name,
            owner,
            statistics,
        }
    })

    return {
        data,
    }
}

/*
export  async function getStats(strategy = undefined) {

    let params = {};

    if (strategy !== undefined && strategy.strip() !== '') {
        params = { strategy, }
    }

    let result = mock_stats2;

    let header = {}

    const resultLst = Object.keys(result).map((item) => {
        const curRow = result[item]
        Object.keys(curRow).forEach((item2) => {
            header[item2] = true
        })
        curRow['strategy'] = item
        return curRow
    })

    header = Object.keys(header).sort().reverse()

    header.unshift('strategy')

    const headerLst = header.map((item) => {
        return { 'text': item, 'value': item }
    })

    return {
        resultLst, 
        headerLst
    }
}
*/

export  async function getTickerPrices(symbols) {
    const response = await axios.get('https://api.binance.com/api/v3/ticker/price')
    // console.log('response:', response.data)

    const result = {}

    response.data.forEach((item) => {
        const keys = Object.keys(symbols)
        const ind = keys.indexOf(item.symbol)
        if (ind > -1) {
            result[symbols[keys[ind]]] = item
        }
    })

    return result
}

export async function login(username, password) {
    const response = await request.post("/login", { username, password, })
    if (response?.data) {
        localStorage.setItem('access_token', response.data.access_token)
    }
}

export async function verify() {
    return await request.post('/verify')
}

/*
[
    {
        "gateway_name": "loopring36_rpc"
    }
]
*/
export async function getGateways() {
    const response = await request.post("/v1/action", { method: "get_gateways" })
    return response.data
}

function sumItem(arr1, arr2) {
    if (arr2.length === 0) {
        return arr1
    } else {
        arr1.map((value, ind) => {
            arr2[ind] += value
        })
        return arr2
    }
}

export async function get_base_balances() {

    const params = {};
    
    let response = await request.post("/v1/action", { method: "get_base_balances", params, })

    return response

}

export async function update_base_balances(params) {

    console.log("update_base_balances:", params);
    
    let response = await request.post("/v1/action", { method: "update_base_balances", params, })

    console.log(response.data);

}

export async function getHistoryBalancesNew() {

    const params = { start: 100000, end: 9999999999, }
    
    let response = null

    response = await request.post("/v1/action", { method: "get_history_balances", params, })

    // if (process.env.NODE_ENV === 'production') {
    //     response = await request.post("/v1/action", { method: "get_history_balances", params, })
    // } else {
    //     response = { data: history_data, }
    // }

    console.log(response.data)

    const value_lst_map = {}

    const base_lst_map = {}

    const pnl_lst_map = {}

    group_names.forEach((group_name) => {
        value_lst_map[group_name] = []
        base_lst_map[group_name] = []
        pnl_lst_map[group_name] = []
    })

    if (response.data) {
        response.data.forEach((daily_item) => {

            let btcPrice = 0;

            let ethPrice = 0;

            const value_item_map = {}
        
            const base_item_map = {}
        
            const pnl_item_map = {}

            group_names.forEach((group_name) => {
                value_item_map[group_name] = 0
                base_item_map[group_name] = 0
                pnl_item_map[group_name] = 0
            })

            const ms_ts = daily_item.timestamp * 1000

            for (let i = 0; i < daily_item.balances.length; i++) {

                const item = daily_item.balances[i]
                if (item.symbol === 'BTC') {
                    btcPrice = item.price ? parseFloat(item.price) : 0
                }

                if (item.symbol === 'ETH') {
                    ethPrice = item.price ? parseFloat(item.price) : 0
                }
            }

            for (let i = 0; i < daily_item.balances.length; i++) {
                
                const balance_item = daily_item.balances[i]

                // if (balance_item.account === 'binance_rpc' && balance_item.symbol === 'WBTC') {
                //     continue;
                // }

                let price = balance_item.price ? parseFloat(balance_item.price) : 0

                if (balance_item.symbol === 'WBTC') {
                    price = btcPrice
                }

                if (balance_item.symbol === 'ETHW') {
                    continue
                }

                const group_name = group_mapping[balance_item.account]

                const value = price * balance_item.balance

                if (!isNaN(value) && value !== undefined) {
                    value_item_map[group_name] += value
                }

                const filtered = (balance_item.account === 'loopring36_rpc' && balance_item.symbol !== 'USDC') 
                                    || (balance_item.account === 'binance U_FUTURES(HEDGE)')
                                    || (balance_item.account === 'binance U_FUTURES(HEDGE)_coinm')
                                    || (balance_item.account === 'binance Ping 2(LTS)')

                if (!filtered) {

                    const base_count = balance_item.base ? parseFloat(balance_item.base) : 0
    
                    const base_value = price * base_count

                    // if (group_name === 'renping') {
                    //     debugger
                    // }
    
                    if (!isNaN(base_value) && base_value !== undefined) {
                        base_item_map[group_name] += base_value
                    }

                }

            }

            group_names.forEach((group_name) => {
                value_lst_map[group_name].push([ms_ts, value_item_map[group_name]])
                base_lst_map[group_name].push([ms_ts, base_item_map[group_name]])
                pnl_lst_map[group_name].push([ms_ts, value_item_map[group_name] - base_item_map[group_name]])
            })

        });
    }

    console.log('value_lst_map:', value_lst_map)
    console.log('base_lst_map:', base_lst_map)
    console.log('pnl_lst_map:', pnl_lst_map)

    return { value_lst_map, base_lst_map, pnl_lst_map, }
    
}

export async function getHistoryBalances() {

    const params = { start: 100000, end: 9999999999, }
    
    let response = null

    response = await request.post("/v1/action", { method: "get_history_balances", params, })

    console.log(response.data)

    // if (process.env.NODE_ENV === 'production') {
    //     response = await request.post("/v1/action", { method: "get_history_balances", params, })
    // } else {
    //     response = { data: history_data, }
    // }

    const items = {}
    const value_items = {}

    const value_total_lst = []

    const base_value_total_lst = []

    const pnl_lst = []

    const timestamp_lst = []

    const datetime_lst = []

    const timestamps = {}

    const datetimes = {}

    const totalBalances = {}

    let baseLst = []

    if (response.data) {
        response.data.forEach((daily_item) => {
            // console.log('timestamp:', )
            // console.log('balances:', item.balances)

            const currBalance = {}

            let value_total = 0

            let base_value_total = 0

            const base_map = {}

            baseLst = []

            let btcPrice = 0;

            for (let i = 0; i < daily_item.balances.length; i++) {

                const item = daily_item.balances[i]
                if (item.symbol === 'BTC') {
                    btcPrice = item.price ? parseFloat(item.price) : 0
                    break
                }
            }

            for (let i = 0; i < daily_item.balances.length; i++) {
                
                const balance_item = daily_item.balances[i]

                if (balance_item.account === 'binance_rpc' && balance_item.symbol === 'WBTC') {
                    continue;
                }

                let price = balance_item.price ? parseFloat(balance_item.price) : 0

                if (balance_item.symbol === 'WBTC') {
                    price = btcPrice
                }

                const value = price * balance_item.balance

                if (!isNaN(value) && value !== undefined) {
                    value_total += value
                }

                if (!base_map.hasOwnProperty(balance_item.symbol)) {

                    const base_count = balance_item.base ? parseFloat(balance_item.base) : 0

                    const base_value = price * base_count

                    if (!isNaN(base_value) && base_value !== undefined) {
                        base_value_total += base_value
                    }

                    base_map[balance_item.symbol] = base_count

                    baseLst.push({'account': group_mapping[balance_item.account], 'symbol': balance_item.symbol, 'base': base_count, });

                }

                if (items.hasOwnProperty(balance_item.account) && items[balance_item.account].hasOwnProperty(balance_item.symbol)) {
                    items[balance_item.account][balance_item.symbol].push(balance_item.balance)
                    value_items[balance_item.account][balance_item.symbol].push(value)
                } else if (items.hasOwnProperty(balance_item.account)) {
                    items[balance_item.account][balance_item.symbol] = [balance_item.balance]
                    value_items[balance_item.account][balance_item.symbol] = [value]
                } else {
                    var newMap = {}
                    newMap[balance_item.symbol] = [balance_item.balance]
                    items[balance_item.account] = newMap

                    var newMap1 = {}
                    newMap1[balance_item.symbol] = [value]
                    value_items[balance_item.account] = newMap1
                }

                if (balance_item.symbol !== undefined) {

                    if (currBalance.hasOwnProperty(balance_item.symbol)) {
                        currBalance[balance_item.symbol] += balance_item.balance
                    } else {
                        currBalance[balance_item.symbol] = balance_item.balance
                    }

                }
            }

            const pnl = value_total - base_value_total

            timestamp_lst.push(daily_item.timestamp)

            const ms_ts = daily_item.timestamp * 1000

            const dt = new Date(ms_ts).toLocaleString()

            datetime_lst.push(dt)

            value_total_lst.push([ms_ts, value_total])
            base_value_total_lst.push([ms_ts, base_value_total])
            pnl_lst.push([ms_ts, pnl])
            
            Object.keys(currBalance).forEach((value) => {
                if (totalBalances.hasOwnProperty(value)) {
                    totalBalances[value].push(currBalance[value])
                } else {
                    totalBalances[value] = [currBalance[value]]
                }

                if (timestamps.hasOwnProperty(value)) {
                    timestamps[value].push(daily_item.timestamp)
                } else {
                    timestamps[value] = [daily_item.timestamp]
                }

                if (datetimes.hasOwnProperty(value)) {
                    datetimes[value].push(dt)
                } else {
                    datetimes[value] = [dt]
                }
            })
        });
    }

    const keys = Object.keys(totalBalances)

    const values = keys.map((key) => {

        const rawData = totalBalances[key]
        const rawTs = timestamps[key]
        const newData = []
        let c1 = 0
        let c2 = 0

        while(c1 < timestamp_lst.length || c2 < rawTs.length) {
            if (timestamp_lst[c1] < rawTs[c2]) {
                newData.push([timestamp_lst[c1] * 1000, 0]);
                c1++;
            } else {
                newData.push([timestamp_lst[c1] * 1000, rawData[c2]]);
                c1++;
                c2++;
            }
        }

        return {
            name: key, 
            type: 'line',
            data: newData,
        }
    })

    return { timestamps, datetimes, items, value_items, 
        value_total_lst, base_value_total_lst, pnl_lst, datetime_lst,
        totalBalances, keys, values, baseLst, }
}

export async function getBalances() {
    
    const response = await request.post("/v1/action", { method: "get_balances" })

    const items = []

    if (response.data) {
        let res = {};
        response.data.forEach((item, index) => {
            let b = {};
            if (res.hasOwnProperty(item.account)) {
                b = res[item.account];
            }
            b[item.symbol] = item.balance;
            res[item.account] = b;
        });

        Object.keys(res).forEach((item,) => {
            items.push({ account: item, balances: res[item], _showDetails: true, })
        })
    }
    console.log(items)
    return items
}

/*
{
    "loopring36_rpc": {
        "markets": [
            {
                "market": "AMM-LRC-ETH",
                "base_decimals": 18,
                "quote_decimals": 18
            },
          ...
          ]
    }
}
*/
export async function getMarkets() {
    const response = await request.post("/v1/action", { method: "get_markets" })

    const marketMap = {}

    const gatewayLst = []

    if (response.data) {
        Object.keys(response.data).forEach((item) => {
            gatewayLst.push(item)
            let res = {}
            res.exchange = response.data[item].exchange
            res.market = response.data[item].markets.map((balance_item) => {
                return balance_item.market
            })
            marketMap[item] = res
        })
    }

    return {
        gatewayLst,
        marketMap,
        raw: response.data,
    }
}

export async function subscribe(gateway_name, market) {
    const params = { gateway_name, market, }
    const response = await request.post("/v1/action", { method: "subscribe", params, })
    // console.log('subscribe:', response.data)
    return response.data
}

export async function getOrders() {
    const response = await request.post("/v1/action", { method: "get_orders" })
    // console.log('getOrders:', response.data)
    return response.data
}

export async function cancelOrder(market, orderid, gateway_name) {
  const params = { market, orderid, gateway_name, };
  const response = await request.post("/v1/action", { method: "cancel_order", params, })
  return response.data
}

// TODO
/*
{
    "method": "send_order",
    "params": {
        "gateway_name": "loopring36_rpc",
        "direction": "BUY",
        "market": "LRC-USDT",
        "price": 1,
        "volume": 100
    }
}
*/
export async function sendOrder({
    gateway_name,
    direction,
    market,
    price,
    volume,
}) {
  const response = await request.post("/v1/action", { method: "send_order", params: {
    gateway_name,
    direction,
    market,
    price,
    volume,
}, })
  return response.data
}

export var myMixins = {
    methods: {
      logout: function() {
        localStorage.removeItem('access_token')
        this.$router.push('/login')
      }
    }
  }
