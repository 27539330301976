<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm6 md4 lg3>
            <v-card class="elevation-12">
              <v-toolbar dark color="secondary">
                <v-toolbar-title>OST Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field color="info" prepend-icon="mdi-account" autocomplete="account" label="Account"
                    type="text" v-model="username"></v-text-field>
                  <v-text-field color="info" :v-model="password" autocomplete="current-password" prepend-icon="mdi-lock"
                    label="Password" @click:append="toggleMarker" :append-icon="marker ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="marker ? 'password' : 'text'" v-model="password"></v-text-field>
                  <v-row justify='center'>
                    <v-col cols="5">ver: {{ ver }}</v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" :loading="loading" v-on:click.stop.prevent="submit">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
        <lc-toast :snackbar="snackbar" :text="snackbarTxt"></lc-toast>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { login } from "@/api";

import LcToast from "@/components/base/Toast"

export default {
  name: "Login",

  components: {
    LcToast,
  },

  mounted: function () { },
  methods: {
    toggleMarker() {
      this.marker = !this.marker
    },
    validate: function () {
      this.emailBlured = true;
      this.passwordBlured = true;
      //   if (this.validEmail(this.email) && this.validPassword(this.password)) {
      //     this.valid = true;
      //   }
    },

    validEmail: function (email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      if (re.test(email.toLowerCase())) {
        return true;
      }
    },

    validPassword: function (password) {
      if (password.length > 7) {
        return true;
      }
    },

    submit: function () {

      this.startLogin();

      this.validate();

      if (this.valid) {
        this.submitted = true;
      }

      login(this.username, this.password).then(() => {
        this.loginSuccess();
        this.$router.push("/");
      }).catch((reason) => {
        this.loginFailed();
      });
    },

    startLogin: function () {
      this.snackbar = false;
      this.loading = true;
    },

    loginSuccess: function () {
      this.snackbarTxt = 'Login Successfully!';
      this.snackbar = true;
      this.loading = false;
    },

    loginFailed: function () {
      this.snackbarTxt = 'Login Failed!';
      this.snackbar = true;
      this.loading = false;
    },
  },
  data() {
    return {
      marker: true,
      snackbarTxt: 'Login Failed!',
      snackbar: false,
      loading: false,
      ver: process.env.VUE_APP_VER,
      username: "admin",
      password: "",
      emailBlured: false,
      valid: false,
      submitted: false,
      passwordBlured: false,
    };
  },
};
</script>

<style>
</style>
