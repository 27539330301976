const mm_group = [ 'binance_rpc', 'loopring36_rpc', 'binance U_FUTURES(HEDGE)', ];

const lchan_group = [ 'binance Lchan', ]

const rp_group = [ 'binance Ping 1', 'binance Ping 2(LTS)', 'binance Ping 2(LTS)_spot', ];

const group_names = [ 'mm', 'lchan', 'renping', ];

const group_mapping = {
    'binance_rpc': 'mm', 
    'loopring36_rpc': 'mm', 
    'binance U_FUTURES(HEDGE)': 'mm',
    'binance U_FUTURES(HEDGE)_coinm': 'mm',
    'binance Lchan': 'lchan',
    'binance Lchan_coinm': 'lchan',
    'binance Lchan_spot': 'lchan',
    'binance Ping 1': 'renping', 
    'binance Ping 1_coinm': 'renping', 
    'binance Ping 2(LTS)': 'renping', 
    'binance Ping 2(LTS)_coinm': 'renping', 
    'binance Ping 2(LTS)_spot': 'renping'
}

const groups = {
    'mm' : mm_group,
    'lchan': lchan_group,
    'renping': rp_group,
}

export {
    group_mapping,
    group_names,
    mm_group,
    lchan_group,
    rp_group,
    groups,
}
