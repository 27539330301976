import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/views/dashboard/Login'

import { verify } from "@/api";

Vue.use(Router)

const SKIP_AUTH = process.env.VUE_APP_SKIP_AUTH === '1'

console.log('SKIP_AUTH:', SKIP_AUTH)

const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    meta: { requiresAuth: !SKIP_AUTH, },
    children: [
      // Dashboard
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/dashboard/Dashboard'),
      },
      // Pages
      {
        name: 'Account',
        path: 'pages/account',
        component: () => import('@/views/dashboard/pages/Account'),
      },
      {
        name: 'Orders',
        path: 'pages/orders',
        component: () => import('@/views/dashboard/pages/Orders'),
      },
      {
        name: 'Mgrtools',
        path: 'pages/mgrtools',
        component: () => import('@/views/dashboard/pages/Mgrtools'),
      },
      {
        name: 'User Profile',
        path: 'pages/user',
        component: () => import('@/views/dashboard/pages/UserProfile'),
      },
      {
        name: 'Notifications',
        path: 'components/notifications',
        component: () => import('@/views/dashboard/component/Notifications'),
      },
    ],
  },
];

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('access_token');
  let requireAuth = to.matched.some(record => record.meta.requiresAuth);

  if (!requireAuth) {
    next();
  }

  if (requireAuth && !token) {
    next('/login');
  }

  if (to.path === '/login') {
    if (token) {
      verify().then(() => {
        next('/');
      }).catch(() => {
        localStorage.removeItem('access_token')
        next();
      });
    }
    else {
      next();
    }
  }

  if (requireAuth && token) {
    verify().then((res) => {
      next();
    }).catch(() => {
      localStorage.removeItem('access_token')
      next('/login');
    })
  }
});

export default router;

