<template>
  <v-dialog v-model="internalDialogFlag" :max-width="max_width">
    <v-card>
      <v-card-title class="text-h5">{{ text }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelFunc">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="confirmFunc">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmDialog",

  props: {
    max_width: {
      type: String,
      default: '300px',
    },
    dialogFlag: {
      type: Boolean,
      default: false,
    },
    cancelFunc: {
      type: Function,
    },
    confirmFunc: {
      type: Function,
    },
    text: {
      type: String,
      default: "Are you sure?",
    },
  },

  computed: {
    internalDialogFlag() {
      return this.dialogFlag;
    }
  },

  data() {
    return {};
  },
};
</script>

<style lang="sass">
</style>
