import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-json-edit'
import vuetify from './plugins/vuetify'

import { ECharts, } from './plugins/vue-echarts'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.component('v-chart', ECharts)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
